.home-wrapper {
    background-attachment: fixed;
    background-image: url('../../assets/home-main-bg.svg');
    /* background-position: 50%; */
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    justify-content: center;
    width: 100%;
}



.home-second-wrapper {
    background-attachment: fixed;
    background-image: url('../../assets/second-home-bg.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
}



.css-cge2yf {
    background: white;
}



.about-us-wrapper {
    background-attachment: fixed;
    background-image: url('../../assets/home-main-bg.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.horizontal-line {
    border-top: 8px solid #01AB55;
    width: 140px;
}



.css-17r9e7a {
    background-color: #151C24;
    background-attachment: fixed;
    background-image: url('../../assets/home-main-bg.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    justify-content: center;
    width: 100%;
}

#about ul {
    list-style: none;
}

#about  ul li::before {
    content: "\2022";
    color: #00FFCC;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    margin-right: 16px;
}

#about  ul li {
    display: flex;
    /* height: 10; */
}

#affiliate ul {
    list-style: none;
}

#affiliate  ul li::before {
    content: "\2022";
    color: #00FFCC;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    margin-right: 16px;
}

#affiliate  ul li {
    display: flex;
    /* height: 10; */
}

.create-account {
    background-image: url('../../assets/horizontal_line.svg');
}

.wallet-sections {
    background-color: '#151C24';
    padding: 24 0 24 24;
}

.faqs-support-button {
    position: absolute;
    top: 40%;
    left: 20%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.divider-contact {
    padding-top: 107px;
}

.contact-field {
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}