.mobile-menu {
    display: flex;
    padding: 8px 16px;
    margin: 0 auto;
    align-items: center;
}

.mobile-menu:active {
    color: rgb(0, 171, 85);
    background-color: rgba(0, 171, 85, 0.08);
}